import React from 'react';
import styled from 'styled-components';

const Logobook = () => {
  return (
    <Container>
      <iframe
        id="logobook"
        title="logobook"
        allow="clipboard-write"
        sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms"
        allowFullScreen="true"
        src="https://e.issuu.com/embed.html?backgroundColor=%231d1d1b&d=agency_x_brandbook_v3&pageLayout=singlePage&u=agency-x"
      />
    </Container>
  );
};

const Container = styled.div`
  height: 0;
  padding-top: max(60%, 326px);
  position: relative;
  width: 100%;

  iframe {
    border: none;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    div {
      display: none !important;
    }
  }
`;

export default Logobook;
